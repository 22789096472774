<template>
  <div>
    <div v-show="activeTab==0">
      <el-card class="card">
        <van-cell-group class="cellgroup">
          <van-cell title="连接状态" :value="connectStatus" :value-class="connectStatus=='已连接'?'textGreen':'textRed'" />
          <van-cell title="序列号" :value="serialNumber" />
          <van-cell title="力值(N)" :value="liZhi" />
          <van-cell title="流值(mm)" :value="liuZhi" />
          <van-cell title="修正值(mm)" :value="xiuZhengZhi" />
          <van-cell title="运行状态" :value="runStatus=='0'?'已停止':'运行中'" :value-class="runStatus==0?'textRed':'textGreen'" />
        </van-cell-group>
      </el-card>
      <!-- <el-row type="flex" justify="center" style="margin-top:150px;margin-left:20px;margin-right:20px;">
        <van-button type="primary" size="large" round @click="startWDD">开始</van-button>
      </el-row> -->
    </div>
    <div v-show="activeTab==1">
      <van-cell-group title="数据内容" class="cellgroup">
        <van-field label="数据地址" type="digit" v-model.number="send_ShuJuDiZhi"></van-field>
        <van-field label="编号" type="digit" v-model="send_BianHao"></van-field>
        <van-field label="时间" is-link readonly @click="popupVisible=true" :value="this.selectDateText"></van-field>
        <van-field label="力值(N)" type="digit" v-model="send_LiZhi"></van-field>
        <van-field label="流值(mm)" type="number" v-model="send_LiuZhi"></van-field>
        <van-field label="修正值(mm)" type="number" v-model="send_XiuZhengZhi"></van-field>
      </van-cell-group>
      <el-row style="margin-left:20px;margin-right:20px;margin-top:100px;">
        <van-button type="info" size="large" @click="send43">发送数据</van-button>
      </el-row>

    </div>
    <van-tabbar v-model="activeTab">
      <van-tabbar-item icon="home-o">设备状态</van-tabbar-item>
      <van-tabbar-item icon="upgrade">发送数据</van-tabbar-item>
    </van-tabbar>
    <van-popup v-model="popupVisible" position="bottom">
      <van-datetime-picker type="datetime" v-model="currentDate" title="选择完整时间" cancel-button-text=" " :formatter="formatterPicker" @confirm="handleConfirmPicker" />
    </van-popup>

  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
import Moment from "moment";
Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      activeTab: 0,
      connectStatus: null,
      shiYanBianHao: null,
      liZhi: null,
      liuZhi: null,
      xiuZhengZhi: null,
      status: null,
      resultStatus: null,
      runStatus: null,
      popupVisible: false,
      selectDate: null,
      selectDateText: null,
      currentDate: new Date(),
      send_ShuJuDiZhi: null,
      send_BianHao: null,
      send_LiZhi: null,
      send_LiuZhi: null,
      send_XiuZhengZhi: null,
    };
  },
  created() {
    this.initWebSocket();
  },
  methods: {
    send43() {
      let that = this;
      if (
        !that.send_ShuJuDiZhi ||
        !that.send_BianHao ||
        !that.selectDate ||
        !that.send_LiZhi ||
        !that.send_LiuZhi ||
        !that.send_XiuZhengZhi
      ) {
        that.$notify({ type: "danger", message: "发送数据不能为空" });
        return;
      }
      if (that.connectStatus == "未连接") {
        that.$notify({ type: "danger", message: "未连接" });
        return;
      }

      that.websocketsend(
        JSON.stringify({
          deviceType: "WDD001",
          serialNumber: that.serialNumber,
          command: "43",
          data:
            "" +
            (that.send_ShuJuDiZhi - 1) +
            "," +
            that.send_BianHao +
            "," +
            (that.selectDate.getFullYear() - 2000) +
            "," +
            (that.selectDate.getMonth() + 1) +
            "," +
            that.selectDate.getDate() +
            "," +
            that.selectDate.getHours() +
            "," +
            that.selectDate.getMinutes() +
            "," +
            "0" +
            "," +
            that.send_LiZhi +
            "," +
            that.send_LiuZhi +
            "," +
            that.send_XiuZhengZhi,
        })
      );
      that.$notify({ type: "success", message: "发送成功" });
    },
    handleConfirmPicker(v) {
      let that = this;
      that.selectDate = v;
      that.selectDateText = new Moment(v).format("YYYY-MM-DD HH:mm");
      that.popupVisible = false;
    },
    formatterPicker(type, val) {
      if (type == "year") return val + "年";
      else if (type == "month") return val + "月";
      else if (type == "day") return val + "日";
      else if (type == "hour") return val + "时";
      else if (type == "minute") return val + "分";
    },
    startWDD() {},
    initWebSocket() {
      const wsuri = process.env.VUE_APP_WebSocketURL;
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      this.websocketsend(
        JSON.stringify({
          deviceType: "WDD001",
          serialNumber: this.serialNumber,
          command: "32",
        })
      );
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      if (e.data == "0") {
        this.connectStatus = "未连接";
      } else {
        this.connectStatus = "已连接";
        let tmpObj = JSON.parse(e.data);
        this.shiYanBianHao = tmpObj.ShiYanBianHao;
        this.liZhi = tmpObj.LiZhi;
        this.liuZhi = tmpObj.LiuZhi;
        this.xiuZhengZhi = tmpObj.XiuZhengZhi;
        this.status = tmpObj.Status;
        this.resultStatus = tmpObj.ResultStatus;
        this.runStatus = tmpObj.RunStatus;
      }
      setTimeout(() => {
        this.websocketsend(
          JSON.stringify({
            deviceType: "WDD001",
            serialNumber: this.serialNumber,
            command: "32",
          })
        );
      }, 200);
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
    },
  },
};
</script>

<style scoped>
.card {
  background-color: #409eff;
}
.textRed {
  color: red;
}
.textGreen {
  color: green;
}
</style>>
